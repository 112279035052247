#education {
  padding-left: 15%;
  padding-right: 25%;
  min-height: 30vh;
  padding-top: 40px;
}

.school_name {
  font-family: "NTR", sans-serif;
  color: var(--lightest-slate);
  font-size: 24px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  gap: 23px;
}

a {
  font-weight: bold;
}

.school_details {
  font-size: 18px;
  color: var(--slate);
  display: flex;
  gap: 23px;
}

.education-spacer {
  height: 20px; /* Adjust the height value to increase or decrease the spacing */
}

@media only screen and (max-width: 600px) {
  #education {
    height: unset;
    padding-left: unset;
    padding-right: unset;
    padding-top: 10%;
  }

  .school_name {
    font-size: 18px;
    text-align: justify;
  }

  .school_details {
    font-size: 15px;
    padding-bottom: 35px;
  }
}
